// stores/userdata.js
import { defineStore } from 'pinia'

export const useRouterStore = defineStore('router', {
  state: () => {
    return { router: '' }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    setRouter( router_new ) {
      this.router = router_new;
    },
    getRouter() {
      return this.router;
    },
  },
})