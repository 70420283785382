//import { createRouter, createWebHistory, useRoute } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

//import Home from '../views/index.vue';
import store from '../store';

import { useUserdataStore } from '@/stores/userdata'

const routes = [
    //dashboard
    //{ path: '/', name: 'Home', component: Home },
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/hpo-in/home/home.vue'),
        //component: () => import(/* webpackChunkName: "home" */ '@/views/hpo-in/home/home.dev.vue'),
    },
    {
        path: '/home',
        name: 'home2',
        component: () => import(/* webpackChunkName: "home" */ '@/views/hpo-in/home/home.vue'),
        //component: () => import(/* webpackChunkName: "home" */ '@/views/hpo-in/home/home.dev.vue'),
    },
    {
        path: '/dealershop',
        name: 'dealershop',
        component: () => import(/* webpackChunkName: "dealershop" */ '@/views/hpo-in/dealershop/dealershop.vue'),
    },
    {
        path: '/zoeken-op-motor/:make_url?/:cc?/:bj?',
        name: 'zoeken-op-motor',
        props: true,
        component: () => import(/* webpackChunkName: "zoeken-op-motor" */ '@/views/hpo-in/dealershop/zoeken-op-motor.vue'),
    },
    {
        path: '/motor-cycle/:strokenr/:maintab?',
        name: 'motor-cycle',
        props: true,
        component: () => import(/* webpackChunkName: "motor-cycle" */ '@/views/hpo-in/dealershop/motor-cycle.vue'),
    },
    {
        path: '/news/:news_id',
        name: 'news-item',
        props: true,
        component: () => import(/* webpackChunkName: "news-item" */ '@/views/hpo-in/news/news-item.vue'),
    },
    {
        path: '/news',
        name: 'news',
        props: true,
        component: () => import(/* webpackChunkName: "news" */ '@/views/hpo-in/news/news.vue'),
    },
    {
        path: '/my-order',
        name: 'my-order',
        props: true,
        component: () => import(/* webpackChunkName: "my-order" */ '@/views/hpo-in/orders/my-order.vue'),
    },
    {
        path: '/order-confirmation',
        name: 'order-confirmation',
        props: true,
        component: () => import(/* webpackChunkName: "my-order" */ '@/views/hpo-in/orders/order-confirmation.vue'),
    },
    {
        path: '/order-history',
        name: 'order-history',
        props: false,
        component: () => import(/* webpackChunkName: "order-history" */ '@/views/hpo-in/orders/order-history.vue'),
    },
    {
        path: '/order/:orderid',
        name: 'order',
        props: true,
        component: () => import(/* webpackChunkName: "order" */ '@/views/hpo-in/orders/order.vue'),
    },
    {
        path: '/part/:hoco_id/:desc?',
        name: 'part',
        props: true,
        component: () => import(/* webpackChunkName: "part" */ '../views/hpo-in/part.vue'),
    },
    {
        path: '/reverse-search/:hoconr?/:make_url?',
        name: 'reverse-search',
        props: true,
        component: () => import(/* webpackChunkName: "reverse-search" */ '../views/hpo-in/dealershop/omgekeerd-zoeken.vue'),
    },
    {
        path: '/search/:search?/:make_id?',
        name: 'search',
        props: true,
        component: () => import(/* webpackChunkName: "search" */ '../views/hpo-in/dealershop/zoeken.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile-edit" */ '../views/hpo-in/profile-edit.vue'),
    },





    {
        path: '/auth/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "auth-logout" */ '../views/auth/logout.vue'),
    },    
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "error404" */ '../views/pages/error404.vue'),
    }
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior( to, from, savedPosition ) 
    {
      if (savedPosition) 
      {
        return savedPosition;
      }
      else
      {
        return { left: 0, top: 0 };
      }
    },
});

router.beforeEach( async (to) => 
{
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login', '/auth/pass-recovery'];
  const authRequired = !publicPages.includes(to.path);
  
  
  const userdataStore = useUserdataStore();
  const tokenid = userdataStore.getTokenid();
  
  if ( authRequired && !tokenid ) //tokenid.length == 0
  {
    //window.location.replace("/auth/login");
    return '/auth/login';
  }

});

router.beforeEach( ( to, from, next ) => 
{
  if (to.meta && to.meta.layout && to.meta.layout == 'auth') 
  {
    store.commit('setLayout', 'auth');
  }
  else 
  {
    store.commit('setLayout', 'app');
  }
  next(true);
});

export default router;