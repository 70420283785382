<script setup>
  import { globals } from '@/globals'
  import { useI18n } from 'vue-i18n';
  const i18n = useI18n();

  const changeLanguage = (item) => {
    selectedLang.value = item;
    window.$appSetting.toggleLanguage(item);

    i18n.locale.value = item.code;
  };
</script>

<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="">Copyright © {{ globals.year }} <a target="_blank" href="">{{ globals.company }}</a>, All rights reserved.</p>
        </div>
        <div class="footer-section f-section-2"></div>
    </div>
    <!-- END FOOTER -->
</template>
