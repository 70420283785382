import { reactive } from 'vue';
//import axios from 'axios';
//import { ref } from 'vue'
//import { provide } from 'vue'
//import { inject } from 'vue'

let language = 'en';
const year = new Date().getFullYear();
const company = 'Hoco Parts';
const projectname = 'HPO';
const api_url = 'https://backend.hocoparts.shop';


export const globals = reactive({year: year, projectname: projectname, api_url: api_url, company: company });
