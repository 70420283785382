<script setup>
  import { computed, onMounted } from 'vue';
  import { defineComponent } from 'vue';
  
  import '@/assets/sass/app.scss';

  import { useMeta } from '@/composables/use-meta';
  import { useStore } from 'vuex';
  //import CartService from '../../../components/data/CartService';

  useMeta({ title: 'HPO' });

  const store = useStore();

  const layout = computed(() => {
      return store.getters.layout;
  });
</script>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';

    export default
    {
        components: 
        {
          app: appLayout,
          auth: authLayout,
        },
        methods: 
        {
        },
/*
        created()
        {
        },
        data()
        {
          data_cart: null
        },
        onMounted()
        {
          CartService.getCart().then(response => {this.data_cart = response.data;}).catch(error => {console.log(error);});
        }
*/        
    };

</script>
<template>
    <div :class="[store.state.layout_style, store.state.menu_style]">
        <component v-bind:is="layout"></component>
    </div>
</template>