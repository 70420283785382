// stores/userdata.js
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core';
//import { userdata } from '@/userdata';

export const useUserdataStore = defineStore('userdata', {
  id: 'userdata',
  state: ()=>({ tokenid: useStorage('')}),
  methods:
  {
    getTokenid() 
    {
      return this.tokenid;
    },
  },
  actions: 
  {
    setTokenid( tokenid_new ) 
    {
      //this.tokenid = useStorage(tokenid_new);
      this.tokenid = tokenid_new;
    },
    getTokenid() 
    {
      return this.tokenid;
    },
    setDealernr( dealernr_new ) 
    {
      //this.dealernr = useStorage(dealernr_new);
      this.dealernr = dealernr_new;
    },
    getDealernr() 
    {
      return this.dealernr;
    },
    
    setIsLoggedIn( isLoggedIn_new ) 
    {
      //this.isLoggedIn = useStorage(isLoggedIn_new);
      this.isLoggedIn = isLoggedIn_new;
    },
    getIsLoggedIn() 
    {
      return this.isLoggedIn;
    },
    
    setLanguage( language_new ) 
    {
      //this.language = useStorage(language_new);
      this.language = language_new;
    },
    getLanguage() 
    {
      return this.language;
    },
    
    setValuta( valuta_new ) 
    {
      //this.valuta = useStorage(valuta_new);
      this.valuta = valuta_new;
    },
    getValuta() 
    {
      return this.valuta;
    },
    
    setCountry( country_new ) 
    {
      //this.country = useStorage(country_new);
      this.country = country_new;
    },
    getCountry() 
    {
      return this.country;
    },      
    setEmail( email_new ) 
    {
      //this.email = useStorage(email_new);
      this.email = email_new;
    },
    getEmail() 
    {
      return this.email;
    },    
    logout() 
    {
      this.tokenid = '';
      this.setTokenid( '' );
      /*
      this.setDealernr('');
      this.setIsLoggedIn(0);
      this.setDealernr('');
      this.setEmail('');
      */
      //this.tokenid = '';
      //this.dealernr = '';
      //this.isLoggedIn = 0;
      //this.country = '';
      //this.email = '';
    },
  },
})