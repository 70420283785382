//import LogRocket from 'logrocket';
//LogRocket.init('cqtji0/hpo');

import { createApp } from 'vue';
import App from './App.dev.vue';
import store from './store';
import VueCookies from 'vue3-cookies'
import { createPinia } from 'pinia'
//import { useCookies } from "vue-cookies";
//import VueFacebookPage from 'vue-facebook-page'

//import Vue from 'vue'
//import { ref } from 'vue'
//import { provide } from 'vue';

/*
import axios from 'axios';
import { reactive } from 'vue'
import { ref } from 'vue'
import { provide } from 'vue'
*/

const app = createApp(App);
app.use(createPinia());

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// hpo style
import '@/assets/css/hpo-global.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
import createI18n from './i18n';
//import { useLanguageStore } from '@/stores/language';
//const language_stored = useLanguageStore();
//language_stored.setNew( createI18n );
//import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
//import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();

//router
import router from './router';

//import { useRouterStore } from '@/stores/router';
//const router_stored = useRouterStore();
//router_stored.setRouter( router );

app.use(createI18n);
app.use(store);
app.use(router);
app.use(PerfectScrollbar);
app.use(VueNouislider);
app.use(Maska);
app.use(ClientTable);
//app.use(vue3JsonExcel);
app.use(VueFormWizard);
app.use(head);
app.use(VueCookies);
app.mount('#app');

//app.use(VueFacebookPage, 'hocopartsnews' );
