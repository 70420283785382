<script setup>
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    const store = useStore();

    const menu_collapse = ref('dashboard');

    onMounted(() => {
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>

<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <!-- <div class="shadow-bottom"></div>-->

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">

<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! NEW MENU !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

              
              <li class="menu">
                <router-link to="/home" class="dropdown-toggle" @click="toggleMobileMenu">
                  <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home">
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                    <span>{{ $t('menu_home') }}</span>
                  </div>
                </router-link>
              </li>
              
              <li class="menu">
                <router-link to="/news" class="dropdown-toggle" @click="toggleMobileMenu">
                  <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cast">
                      <path d="M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6"></path>
                      <line x1="2" y1="20" x2="2.01" y2="20"></line>
                    </svg>
                    <span>{{ $t('menu_news') }}</span>
                  </div>
                </router-link>
              </li>

              <li class="menu">
                <router-link to="/dealershop" class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#dealershop" aria-controls="dealershop" aria-expanded="false">
                  <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                    <span>{{ $t('menu_dealershop') }}</span>
                  </div>
                </router-link>

                <ul id="dealershop" class="collapse submenu list-unstyled" data-bs-parent="#dealershop">
                  <!--<li><router-link to="/dealershop" @click="toggleMobileMenu">{{ $t('menu_dealershop') }}</router-link></li>-->
                  <li><router-link to="/zoeken-op-motor" @click="toggleMobileMenu">{{ $t('menu_zom') }}</router-link></li>
                  <!--<li><router-link to="/zoeken-op-band" @click="toggleMobileMenu">{{ $t('menu_zob') }}</router-link></li>-->
                  <!--<li><router-link to="/kleding-en-helmen" @click="toggleMobileMenu">{{ $t('menu_kh') }}</router-link></li>-->
                  <li><router-link to="/reverse-search" @click="toggleMobileMenu">{{ $t('menu_oz') }}</router-link></li>
                  <li><router-link to="/search" @click="toggleMobileMenu">{{ $t('menu_zop') }}</router-link></li>
                </ul>
              </li>
                
              <li class="menu">
                <a id="id-order" class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#order" aria-controls="order" aria-expanded="false">
                <div class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                  <span>{{ $t('menu_order') }}</span>
                </div>
                </a>
                <ul id="order" class="collapse submenu list-unstyled" data-bs-parent="#order">
                  <li><router-link to="/my-order" @click="toggleMobileMenu">{{ $t('menu_my_order') }}</router-link></li>
                  <li><router-link to="/order-history" @click="toggleMobileMenu">{{ $t('menu_order_history') }}</router-link></li>
                </ul>
              </li>


            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>


